import React from "react"
import cross from "../../assets/images/screenshots/cross.png"

const AntiList = () => {
  return (
    <section className="pt-8 pt-md-10">
      <div className="container">
        {/* <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <h2 className="">
              What you<span className="text-success"> won't </span>get with Lumis.ai
            </h2>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="row align-items-center mb-8 aos-init aos-animate" data-aos="fade-up">
              <div className="col-3 col-lg-5">
                <img src={cross} alt="..." className="img-fluid" />
              </div>
              <div className="col-9 col-lg-7">
                <h3 className="font-weight-bold mb-2">No links to other events</h3>

                <p className="text-gray-700 mb-0">
                  Never tolerate other events being promoted from your ticketing page.
                </p>
              </div>
            </div>

            <div className="row align-items-center mb-8 aos-init aos-animate" data-aos="fade-up">
              <div className="col-3 col-lg-5">
                <img src={cross} alt="..." className="img-fluid" />
              </div>
              <div className="col-9 col-lg-7">
                <h3 className="font-weight-bold mb-2">No branding wars</h3>

                <p className="text-gray-700 mb-0">
                  We’re not in competition with your brand. Your logo is always primary.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="row align-items-center mb-8 aos-init aos-animate" data-aos="fade-up">
              <div className="col-3 col-lg-5">
                <img src={cross} alt="..." className="img-fluid" />
              </div>
              <div className="col-9 col-lg-7">
                <h3 className="font-weight-bold mb-2">No cross-promotion</h3>

                <p className="text-gray-700 mb-0">
                  The data belongs to you. We'll never use it to promote other events.
                </p>
              </div>
            </div>

            <div className="row align-items-center mb-8 aos-init aos-animate" data-aos="fade-up">
              <div className="col-3 col-lg-5">
                <img src={cross} alt="..." className="img-fluid" />
              </div>
              <div className="col-9 col-lg-7">
                <h3 className="font-weight-bold mb-2">No forced accounts </h3>

                <p className="text-gray-700 mb-0">
                  Do you need another password in your life? Neither do your customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AntiList
