import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import ebriteCompare from "../../../assets/images/campaign-eventbrite/compare-eventbrite.png"

const EventbriteOne = () => {
  const intl = useIntl()

  return (
    <section className="position-relative pt-8 pt-md-10 pb-8 pb-md-12">
      <Container>
        <Row className="align-items-center">
          <Col md="6" lg="7" className="order-md-2">
            <div className="w-md-150 w-lg-130 position-relative mb-8">
              <img
                src={ebriteCompare}
                className="position-relative screenshot img-fluid"
                alt="Eventbrite Compare"
              />
            </div>
          </Col>
          <Col md="6" lg="5" className="order-md-1">
            <h2
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage: "A Typical Event Page <br /> on Eventbrite",
                }),
              }}
            />

            <div className="d-flex">
              <div className="badge badge-rounded-circle badge-danger-soft mt-1 mr-4">
                <span className="fe fe-check"></span>
              </div>

              <p
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    defaultMessage: 'Your logo <strong class="text-danger">is not</strong> on top',
                  }),
                }}
              />
            </div>

            <div className="d-flex">
              <div className="badge badge-rounded-circle badge-danger-soft mt-1 mr-4">
                <span className="fe fe-check"></span>
              </div>

              <p
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    defaultMessage: '<strong class="text-danger">Unrelated</strong> links',
                  }),
                }}
              />
            </div>

            <div className="d-flex">
              <div className="badge badge-rounded-circle badge-danger-soft mt-1 mr-4">
                <span className="fe fe-check"></span>
              </div>

              <p
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    defaultMessage:
                      'Cross promoting <strong class="text-danger">other events</strong>',
                  }),
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EventbriteOne
