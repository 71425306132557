import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { EVENTBRITE } from "../../../../campaign-config"
import LeadGeneration from "../../LeadGeneration"

const LeadCapture = () => {
  const intl = useIntl()

  return (
    <LeadGeneration campaign={EVENTBRITE} className="btn-success btn-block">
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            defaultMessage:
              'Request a <span class="text-success">free personal tour</span> through Lumis.ai',
          }),
        }}
      />
    </LeadGeneration>
  )
}

export default LeadCapture
