import { useIntl } from "gatsby-plugin-intl"
import React from "react"

const Comparison = () => {
  const intl = useIntl()

  return (
    <section className="pt-8 pt-md-11 pb-5 bg-gray-200">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <h1
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage: 'A <span class="text-success">superior</span> ticket system.',
                }),
              }}
            />

            <p className="font-size-lg text-gray-700 mb-7 mb-md-9">
              {intl.formatMessage({
                defaultMessage: "Switching to Lumis.ai can save you thousands.",
              })}
            </p>
          </div>
        </div>
        <table className="table border-light table-responsive  rounded">
          <thead>
            <tr>
              <th scope="col">
                <small className="d-sm-none">
                  {intl.formatMessage({ defaultMessage: "Swipe left" })}
                </small>
              </th>
              <th scope="col">Lumis.ai</th>
              <th scope="col">eventbrite</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{intl.formatMessage({ defaultMessage: "Basis Fee" })}</th>
              <td>3,5% </td>
              <td>
                3.5% + $1.59
                <a
                  href="https://www.eventbrite.com/fees"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="small"
                >
                  {" "}
                  {intl.formatMessage({ defaultMessage: "Source" })}
                </a>{" "}
              </td>
            </tr>
            <tr>
              <th scope="row">{intl.formatMessage({ defaultMessage: "Maximum Fee" })}</th>
              <td>$30.00 (€25.00) </td>
              <td>
                {intl.formatMessage({ defaultMessage: "No maximum fee" })}
                <a
                  href="https://www.eventbrite.com/fees"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="small"
                >
                  {" "}
                  {intl.formatMessage({ defaultMessage: "Source" })}
                </a>{" "}
              </td>
            </tr>
            <tr>
              <th scope="row">{intl.formatMessage({ defaultMessage: "Instant Payout" })}</th>
              <td
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    defaultMessage:
                      'Money <strong class="text-success">instantly</strong> available',
                  }),
                }}
              />
              <td>
                <span
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      defaultMessage:
                        'Money payout only <strong class="text-danger">after</strong> the event',
                    }),
                  }}
                />
                &nbsp;{" "}
                <a
                  href="https://www.eventbrite.com/support/articles/en_US/Troubleshooting/eventbrite-merchant-agreement?lg=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="small"
                >
                  {" "}
                  {intl.formatMessage({ defaultMessage: "Source" })}
                </a>{" "}
              </td>
            </tr>

            <tr>
              <th scope="row">{intl.formatMessage({ defaultMessage: "Email Capture" })}</th>
              <td>{intl.formatMessage({ defaultMessage: "GDPR compliant email capture" })}</td>
              <td>
                {intl.formatMessage({ defaultMessage: "Restricted access to emails" })}{" "}
                <a
                  href="https://www.eventbrite.com/support/articles/en_US/Troubleshooting/eventbrite-privacy-policy?lg=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="small"
                >
                  {" "}
                  {intl.formatMessage({ defaultMessage: "Source" })}
                </a>{" "}
              </td>
            </tr>
            <tr>
              <th scope="row">{intl.formatMessage({ defaultMessage: "Custom Domain" })}</th>
              <td>{intl.formatMessage({ defaultMessage: "Link your own domain" })}</td>
              <td>{intl.formatMessage({ defaultMessage: "Not available" })}</td>
            </tr>
          </tbody>
        </table>
        <p className="text-muted px-2">
          <small>
            {intl.formatMessage({
              defaultMessage:
                "* Eventbrite pricing comparison based on published pricing as of Sep 2019. Contact Eventbrite for their complete pricing information. Pricing is subject to change without notice.",
            })}
          </small>
        </p>
      </div>
    </section>
  )
}

export default Comparison
