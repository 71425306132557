import React from "react"
import feature1 from "../../assets/images/screenshots/feature1.jpg"
import feature2 from "../../assets/images/screenshots/feature2.jpg"
import feature3 from "../../assets/images/screenshots/feature3.jpg"

const TenX = () => {
  return (
    <section className="pt-8 pt-md-11 bg-gradient-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <h1>
              <strong>Lumis.ai</strong>
              <br />
              Not just different. <span className="text-success">10X</span> better.
            </h1>

            <p className="font-size-lg text-gray-700 mb-7 mb-md-9">
              Nobody in the industry is able to offer these features.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up">
            <div className="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
              <img src={feature1} alt="..." className="card-img-top" />

              <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                  <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>

              <div className="card-body position-relative">
                <div className="position-relative text-right mt-n8 mr-n4 mb-3">
                  <span className="badge badge-pill badge-warning">
                    <span className="h6 text-uppercase">Predictive Pricing</span>
                  </span>
                </div>
                <h3>More profits</h3>

                <p className="text-muted">
                  Our AI is learning with every ticket sale. We can help you set prices based on
                  data.
                </p>

                <a href="#!" className="font-weight-bold text-decoration-none text-warning d-none">
                  Watch example
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-4 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
              <img src={feature2} alt="..." className="card-img-top" />

              <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                  <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>

              <div className="card-body position-relative">
                <div className="position-relative text-right mt-n8 mr-n4 mb-3">
                  <span className="badge badge-pill badge-success">
                    <span className="h6 text-uppercase">Auto Surveys</span>
                  </span>
                </div>
                <h3>More insights</h3>

                <p className="text-muted">
                  We send surveys after ticket purchase and after the event and automatically
                  evaluate.
                </p>

                <a href="#!" className="font-weight-bold text-decoration-none text-success d-none">
                  Watch example
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-4 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="card shadow-light-lg lift lift-lg">
              <img src={feature3} alt="..." className="card-img-top" />

              <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                  <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>

              <div className="card-body position-relative">
                <div className="position-relative text-right mt-n8 mr-n4 mb-3">
                  <span className="badge badge-pill badge-primary">
                    <span className="h6 text-uppercase">Email Capture</span>
                  </span>
                </div>
                <h3>More Contacts</h3>

                <p className="text-muted">
                  Collect more emails that are GDPR compliant and will grow your audience by a
                  factor of 3X.
                </p>

                <a href="#!" className="font-weight-bold text-decoration-none text-primary d-none">
                  Watch example
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TenX
