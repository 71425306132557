import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import AntiList from "../components/AntiList"
import Comparison from "../components/campaign-eventbrite/Comparison"
import EventbriteOne from "../components/campaign-eventbrite/EventbriteOne"
import Hero from "../components/campaign-eventbrite/Hero"
import LeadCapture from "../components/campaign-eventbrite/LeadCapture"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import TenX from "../components/TenX"

const Eventbrite = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ defaultMessage: "Compare Lumis.ai" })} />
      <div>
        <Hero />
        <Comparison />
        <EventbriteOne />
        <section className="pt-8 pt-md-11 pb-5 bg-gray-200">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 text-center">
                <h1>
                  Stop being<span className="text-danger"> exploited.</span>
                </h1>

                <p className="font-size-lg text-gray-700 mb-7 mb-md-9">
                  Take a look at eventbrite's fine print
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card card-border border-danger shadow-lg mb-6 mb-md-8 lift lift-lg">
                  <div className="card-body text-center">
                    <h4 className="font-weight-bold">Delayed Funding</h4>

                    <p className="text-gray-700 mb-5">
                      eventbrite generally waits up to 5 days{" "}
                      <strong className="text-danger">after</strong> your event to transfer your
                      funds.
                    </p>

                    <a
                      href="https://www.eventbrite.com/support/articles/en_US/Troubleshooting/eventbrite-merchant-agreement?lg=en_US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="badge badge-pill badge-dark-soft">
                        <span className="h6 text-uppercase">4.3 | service agreement</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <div className="card card-border border-danger shadow-lg mb-6 mb-md-8 lift lift-lg">
                  <div className="card-body text-center">
                    <h4 className="font-weight-bold">Solicitation of Attendees</h4>

                    <p className="text-gray-700 mb-5">
                      Terms include giving permission to send offers to your customers for other
                      events.
                    </p>

                    <a
                      href="https://www.eventbrite.com/support/articles/en_US/Troubleshooting/eventbrite-terms-of-service?lg=en_US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="badge badge-pill badge-dark-soft">
                        <span className="h6 text-uppercase">4.4, 10.2a | service agreement</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card card-border border-danger shadow-lg mb-6 mb-md-8 lift lift-lg">
                  <div className="card-body text-center">
                    <h4 className="font-weight-bold">Data Sharing With Affiliates</h4>

                    <p className="text-gray-700 mb-5">
                      They use your attendees’ data for internal business purposes and to share with
                      their affiliates.
                    </p>

                    <a
                      href="https://www.eventbrite.com/support/articles/en_US/Troubleshooting/eventbrite-privacy-policy?lg=en_US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="badge badge-pill badge-dark-soft">
                        <span className="h6 text-uppercase">4.3 | privacy policy</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TenX />
        <div className="my-8"></div>
        <LeadCapture />
        <AntiList />
      </div>
    </Layout>
  )
}

export default Eventbrite
