import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import compare from "../../../assets/images/screenshots/eventbrite-compare.png"

const Hero = () => {
  const intl = useIntl()

  return (
    <section className="pt-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-5 col-lg-6 order-md-2">
            <img
              src={compare}
              className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
              alt="compare lumis"
            />
          </div>
          <div
            className="col-12 col-md-7 col-lg-6 order-md-1 aos-init aos-animate"
            data-aos="fade-up"
          >
            <h1 className="display-3 text-center text-md-left">
              {intl.formatMessage({ defaultMessage: "Why switch?" })}
            </h1>

            <p className="lead text-center text-md-left text-muted mb-6 mb-lg-8">
              {intl.formatMessage({
                defaultMessage: "A comparison between Lumis.ai and eventbrite",
              })}
            </p>
            {/* <Link to="start" className="btn btn-primary btn-lg lift">
              Request Free Access
            </Link> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
